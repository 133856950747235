import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Product from "../components/product"

const ProductPage = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <Product
        limited
        title="Lady Day"
        description={description}
        shortDescription="A portrait inspired by the mesmerising and infinitely elegant jazz singer Billie Holiday. Printed beautifully on Hahnemühle museum etching 350 gsm paper using the highest quality museum grade giclée pigment printing, ensuring lasting intense colors and rich tone depth."
        price={105}
        images={[data.ladyday_1, data.ladyday_2]}
        social={data.social}
        gumroadlink="https://gum.co/dzWja"
        slug="/ladyday/"
      />
    </>
  )
}

const query = graphql`
  fragment image on File {
    childImageSharp {
      fluid(maxWidth: 1340, quality: 95) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    social: file(relativePath: { eq: "ladyday_1.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ladyday_1: file(relativePath: { eq: "ladyday_1.jpg" }) {
      ...image
    }
    ladyday_2: file(relativePath: { eq: "ladyday_2.jpg" }) {
      ...image
    }
  }
`
const description = (
  <>
    <p>
      ‘Lady day’, a portrait inspired by the mesmerising and infinitely elegant
      Jazz singer Billie Holiday.
    </p>

    <p>
      Printed beautifully on Hahnemühle museum etching 350 gsm paper using the
      highest quality museum grade giclée pigment printing, ensuring lasting
      intense colors and rich tone depth.
    </p>

    <p>Size 50 cm × 70 cm</p>

    <p>Numbered edition of 50</p>

    <p>
      All prints are made on heavy quality paper, so all prints are shipped flat
      packaged to ensure delivery of the print in mint condition.
    </p>
  </>
)

export default ProductPage
